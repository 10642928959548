import React from 'react';
import './Banner.css';
import productImage1 from './product1.png';
import productImage2 from './product2.png';
import productImage3 from './product3.png';

const Banner: React.FC = () => {
  return (
    <section className="banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1>Welcome to Habitaly - Habit Daily Tracker</h1>
            <p>An app that helps you build and maintain daily habits easily, creating lasting positive change.</p>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-4">
                <img src={productImage1} alt="Product 1" className="img-fluid rounded shadow-lg mb-3" />
              </div>
              <div className="col-4">
                <img src={productImage2} alt="Product 2" className="img-fluid rounded shadow-lg mb-3" />
              </div>
              <div className="col-4">
                <img src={productImage3} alt="Product 3" className="img-fluid rounded shadow-lg mb-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
