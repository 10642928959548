import React from 'react';
import './ContactInfo.css';

const ContactInfo: React.FC = () => {
  return (
    <section className="contact-info">
      <div className="container">
        <h2>Contact Us</h2>
        <p>Get in touch for any questions or feedback.</p>
        <ul>
          <li><strong>Developer:</strong> Huy Ta</li>
          <li><strong>Email:</strong> <a href="mailto:huytbt@gmail.com">huytbt@gmail.com</a></li>
          <li><strong>Address:</strong> Hoa Quy Ward, Ngu Hanh Son District, Da Nang, Vietnam</li>
          <li><strong>Working Hours:</strong> Mon - Fri, 9:00 AM - 5:00 PM</li>
        </ul>
      </div>
    </section>
  );
};

export default ContactInfo;
