import React, { useEffect } from 'react';
import './Header.css';

const Header: React.FC = () => {
  useEffect(() => {
    // Lấy canvas và context khi component được render
    const canvas = document.getElementById('logoCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    // Vẽ logo "hy"
    if (ctx) {
      ctx.fillStyle = '#f0ad4e'; // Màu cam
      ctx.font = 'bold 50px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('hy', canvas.width / 2, canvas.height / 2);
    }
  }, []); // Empty dependency array ensures useEffect runs only once after initial render

  return (
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <canvas id="logoCanvas" width="70" height="50"></canvas>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/#banner">Hello</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#app-info">Habitaly</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#contact-info">Contact Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#contact-form">Support</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
