import React, { useState } from 'react';
import './ContactForm.css';
import { database } from '../firebase';
import { child, push, ref, set } from 'firebase/database';

const ContactForm: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const contact = {
        name: name,
        email: email,
        message: message,
        createdAt: (new Date()).toISOString()
      };
      const key = push(child(ref(database), 'contactForms')).key;
      await set(ref(database, 'contactForms/' + key), contact);

      setShowSuccessDialog(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error: any) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again later.');
    }
  };

  return (
    <section className="contact-form">
      <div className="container">
        <h3>Send us a Message</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows={5}
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form>
        <p className="support-info">
          Our support team typically responds within 24 hours.
        </p>
      </div>

      {/* Dialog để thông báo gửi thành công */}
      {showSuccessDialog && (
        <div className="overlay">
          <div className="dialog">
            <h2>Message Sent Successfully!</h2>
            <p>Your message has been sent successfully. We will get back to you soon.</p>
            <button className="btn btn-primary" onClick={() => setShowSuccessDialog(false)}>Close</button>
          </div>
        </div>
      )}
    </section>
  );
};

export default ContactForm;
