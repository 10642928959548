// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Banner from './components/Banner';
import AppInfo from './components/AppInfo';
import ContactInfo from './components/ContactInfo';
import ContactForm from './components/ContactForm';
import Privacy from './components/Privacy'; // Import Privacy component
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<>
              <section id="banner">
                <Banner />
              </section>
              <section id="app-info">
                <AppInfo />
              </section>
              <section id="contact-info">
                <ContactInfo />
              </section>
              <section id="contact-form">
                <ContactForm />
              </section>
            </>} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
